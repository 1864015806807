<template>
  <div class="page-z">
    <div class="header">
      <div class="header-left">
        <!-- <img src="../../assets/img/shift/fanhui2.png"> -->
      </div>
      <div class="header-desc">详情</div>
    </div>
    <div class="content">
      <div class="content-tag">
        <!-- 网站 -->
        <div class="tag-o">{{ misreInfo.platformName }}</div>
        <div class="tag-r" @click="lookText">查看原文</div>
        <!-- <div class="tag-p">已预警</div> -->
      </div>
      <div class="content-title">
        {{ misreInfo.title }}
        <!-- <img src="../../assets/img/shift/fuzhi2.png" @click="$main.copyFun(misreInfo.sourceUrl,'复制标题')" alt=""> -->
      </div>
      <div class="content-desc">
        <!-- <div class="content-desc-l">原文链接<img src="../../assets/img/shift/fuzhi.png"></div> -->
        <div class="content-desc-l">发布时间：</div>
        <div class="content-desc-c">{{ misreInfo.publishTime }}</div>
        <!-- <div class="content-desc-r">相似 12</div> -->
      </div>
      <div class="content-bottom">
        <div class="content-bottom-item" v-if="misreInfo.platformType == 1">
          <div class="bottom-item-l">网站名称</div>
          <div class="bottom-item-r">{{ misreInfo.source }}</div>
        </div>
        <div class="content-bottom-item" v-else>
          <div class="bottom-item-l">作者名称</div>
          <div class="bottom-item-r">{{ misreInfo.author }}</div>
        </div>
        <div class="content-bottom-item">
          <div class="bottom-item-l">主体类型</div>
          <div class="bottom-item-r">
            {{ misreInfo.bodyTypeName ||misreInfo.bodyName || "暂无信息" }}
          </div>
        </div>
        <div class="content-bottom-item">
          <div class="bottom-item-l">有害类型</div>
          <div class="bottom-item-r textHidden1" style="color:red">
            {{ misreInfo.machineMessageTypesName || "暂无信息" }}
          </div>
        </div>
        <div class="content-bottom-item">
          <div class="bottom-item-l">所在地区</div>
          <div class="bottom-item-r">
            {{ misreInfo.areaName || "暂无信息" }}
          </div>
        </div>
        <div class="content-bottom-item">
          <div class="bottom-item-l">原文链接</div>
          <div class="bottom-item-r textHidden1">
            {{ misreInfo.sourceUrl || "暂无信息" }}
          </div>
        </div>
        <div class="content-bottom-item">
          <div class="bottom-item-l">命中词</div>
          <div class="bottom-item-r textHidden1">
            {{ misreInfo.matchKeyword || "暂无信息" }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="err">
      <div class="err-header">错误表述</div>
      <div class="err-content">
        <div class="err-content-top">
          <div class="err-red">错误表述</div>
          <div class="err-update">{{ misreInfo.matchKeyword || "无" }}</div>
        </div>
        <div class="err-content-bottom">
          <div class="err-green">建议更正</div>
          <div class="err-update">{{ misreInfo.correctKeyword || "无" }}</div>
        </div>
      </div>
    </div> -->
    <div class="info">
      <div class="info-title">内容</div>
      <div class="info-main" v-html="misreInfo.content || '暂无内容'"></div>
    </div>
    <van-button @click="copyFun(misreInfo.copyText)" class="BBtn" type="warning"
      >一键复制</van-button
    >
  </div>
</template>
   
  <script>
import { detail } from "../../api/shift/shift";
export default {
  data() {
    return {
      misreInfo: {},
    };
  },
  mounted() {
    this.detail();
  },
  methods: {
    lookText() {
      window.open(this.misreInfo.sourceUrl);
    },
    //获取信息详情
    async detail() {
      // let data = this.$route.query.dataId ;
      let data = '738077324134047746' ;
      let res = await detail(data);
      if (res.data.code == 200) {
        this.misreInfo = res.data.data || [];
        // this.misreInfo.matchKeyword = '苏丹 首都'
        this.misreInfo.matchKeyword.split(" ").forEach((item) => {
            let regExp = new RegExp(item, "g");
            this.misreInfo.content = this.misreInfo.content.replace(regExp, "<font style='color:red;font-weight:bold;font-size:18px'>" + item + "</font>");
          });
      }
    },
    copyFun(str) {
      let reg = new RegExp("null", "g"); //g代表全部
      if (str) {
        str = str.replace(reg, "无");
      }
      let htmlGPs = new RegExp("<br/>", "g");
      if (str) {
        str = str.replace(htmlGPs, ",");
      }
      let htmlGPss = new RegExp("<em style='color:red'>", "g");
      if (str) {
        str = str.replace(htmlGPss, " ");
      }
      let htmlGPst = new RegExp("<font color='red'>", "g");
      if (str) {
        str = str.replace(htmlGPst, " ");
      }
      let htmlGPst2 = new RegExp("</font>", "g");
      if (str) {
        str = str.replace(htmlGPst2, " ");
      }
      let htmlGPssem = new RegExp("</em>", "g");
      if (str) {
        str = str.replace(htmlGPssem, "");
      }
      let htmlGPsss = new RegExp("&nbsp;", "g");
      if (str) {
        str = str.replace(htmlGPsss, "");
      }
      let htmlGPssss = new RegExp("<p>", "g");
      if (str) {
        str = str.replace(htmlGPssss, "");
      }
      let htmlGPsssss = new RegExp("</p>", "g");
      if (str) {
        str = str.replace(htmlGPsssss, "");
      }
      if (str) {
        str = str.replace(/<span style="color:red">/g, "");
        str = str.replace(/<\/span>/g, "");
      }
      // if (str) {
      //   str = str.replace(/[<em style="color:red"></em>/&nbsp;/<p>/</p>]/g, "");
      // }
      var textar = document.createElement("textarea");
      textar.value = str;
      document.body.appendChild(textar);
      textar.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      textar.remove();
      // Message.success(title ? `${title}成功！` : "复制成功！");
      this.$toast.success("复制成功");
    },
    dataCopy(msg) {
      let copyContent = msg.replace(/<\/?.+?\/?>/g, "").replace(/&nbsp;/gi, "");
      this.$publicFun.copyFun(copyContent);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.red {
  color: red;
}
.page-z {
  width: 100%;
  background-color: #f3f4f7;
  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-bottom: 1px solid #e5e5e5;
    background-color: white;
    height: 42px;
    .header-left {
      // img {
      //   width: 24px;
      //   height: 42px;
      // }
    }
    .header-desc {
      position: absolute;
      left: 45%;
      width: 64px;
      height: 45px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
    }
  }
  .content {
    padding: 30px;
    margin-bottom: 30px;
    background-color: white;
    .content-tag {
      display: flex;
      justify-content: space-between;
      div {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        margin-right: 5px;
      }
      .tag-o {
        width: auto;
        padding: 0 20px;
        height: auto;
        background: #f58030;
        border-radius: 4px;
      }
      .tag-r {
        width: auto;
        height: 40px;
        background: #1aaef3;
        border-radius: 4px;
        padding: 0 20px;
      }
      .tag-p {
        width: 96px;
        height: 40px;
        background: #ffedef;
        border-radius: 4px;
      }
    }
    .content-title {
      margin-top: 10px;
      margin-bottom: 16px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 40px;
      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
      }
    }
    .content-desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      // margin-bottom: 28px;
      .content-desc-l {
        height: 37px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f58030;
        display: flex;
        align-items: center;
        // line-height: 37px;
        img {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          margin-left: 3px;
        }
      }
      .content-desc-c {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .content-desc-r {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .content-bottom {
      display: flex;
      flex-direction: column;
      .content-bottom-item {
        display: flex;
        justify-content: space-between;
        // height: 40px;
        // margin-bottom: 15px;
        .bottom-item-l {
          min-width: 100px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
        }
        .bottom-item-r {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 40px;
        }
      }
    }
  }
  .info {
    // position: relative;
    background-color: white;
    padding: 20px 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    .info-title {
      // position: absolute;
      // left: 40%;
      width: 136px;
      height: 40px;
      background: #ededed;
      border-radius: 8px;
      text-align: center;
      line-height: 40px;
      margin-bottom: 20px;
      font-size: 16px;
    }
    .info-main {
      // margin-top: 100px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      text-indent: 2rem;
      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
      }
    }
  }
  .err {
    background-color: white;
    .err-header {
      padding: 4px 30px;
      // height: 82px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 42px;
      border-bottom: 1px solid #e5e5e5;
    }
    .err-content {
      padding: 20px 30px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 14px;
      .err-content-top {
        display: flex;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 37px;
        .err-red {
          width: 100px;
          height: 40px;
          background: #ffedef;
          border-radius: 8px;
          margin-top: 10px;
          color: #ea3342;
          text-align: center;
          margin-right: 10px;
        }
        .err-update {
          flex: 1;
          height: auto;
          margin-top: 10px;
          margin-left: 10px;
          text-align: left;
        }
      }
      .err-content-bottom {
        display: flex;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 37px;
        text-align: cneter;

        .err-green {
          width: 100px;
          height: 40px;
          background: #e4f9f2;
          border-radius: 8px;
          margin-top: 10px;
          color: #02bc7c;
          text-align: center;
          margin-right: 10px;
        }
        .err-update {
          flex: 1;
          height: auto;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }
  }
  .BBtn {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 20px;
    font-size: 16px;
  }
}
.textHidden1 {
  // text-overflow: -o-ellipsis-lastline;
  // overflow: hidden;
  // text-overflow: ellipsis;
  /*中文换行*/
  display: -webkit-box;

  word-break: break-all;
  /*英文换行*/
  white-space: wrap;
  /*英文换行*/
  -webkit-line-clamp: 1;
  /*设置显示的行数*/
  line-clamp: 1;
  /*设置显示的行数*/
  // -webkit-box-orient: vertical;
}
</style>